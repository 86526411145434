function hvCenteringBox(rowOrColumn){
    return {
        display: 'flex',
        flexDirection:rowOrColumn,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

const styles = {
    link:{
        textDecoration:"underline",
        cursor:'pointer',
        userSelect:'none'
    }
}

function mix(...objs){
    var style = {}
    objs.forEach(item=>{Object.assign(style,item)})
    return style
}

export {

    hvCenteringBox,
    mix

}

export default styles