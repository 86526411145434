import application_constants from "./application_constants.mjs";
import axios from "axios";
import jsutils from "./jsutils.mjs";

var BACKEND_URL = application_constants.BACKEND_URL;

function normalize_url(url) {
  if (url.includes("?")) {
    var url_parts = url.split("?");
    return normalize_url(url_parts[0]) + "?" + url_parts.slice(1).join("?");
  }
  return url.replace(/\/+/g, "/").replace(/^http(s?):\//g,"http$1://")
}

var axiosToBackend = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
});

var postToProtectedRoute = async function (url, userToken, data, ...args) {
  var postData = {};
  Object.assign(postData, data);
  postData.userToken = userToken;
  return axiosToBackend.post(url, postData, ...args);
};

function summarizeError(e) {
  if (typeof e !== "object") {
    return e;
  }
  if (axios.isAxiosError(e)) {
    return JSON.parse(
      jsutils.tryStringify(
        {
          errorType: "axiosError",
          responseStatusCode: e.response?.status,
          responseData: e.response?.data,
        },
        false
      )
    );
  } else if (e instanceof Error) {
    return {
      message: e.message,
      stack: e.stack,
    };
  } else {
    return JSON.parse(
      jsutils.tryStringify(
        {
          errorType: "Error",
          errorObject: e,
        },
        false
      )
    );
  }
}

// --- Courtesy of https://stackoverflow.com/a/6234804/5166365
function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}
// ---

// --- Adapted from https://stackoverflow.com/a/6234804/5166365
function unescapeHtml(safe) {
  return safe
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
}
// ---

const iface = {
  normalize_url,
  axiosToBackend,
  postToProtectedRoute,
  summarizeError,
  escapeHtml,
  unescapeHtml,
};

export default iface;
