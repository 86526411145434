import webutils from "./webutils.mjs"

const dirname = (filepath)=>{
    const parts = webutils.normalize_url(filepath).split("/")
    return parts.slice(0,parts.length-1).join("/")
}

const basename = (filepath)=>{
    const parts = webutils.normalize_url(filepath).split("/")
    return parts[parts.length-1]
}

export default {
    dirname:dirname,
    basename:basename
}