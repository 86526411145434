import {
    useEffect,
    useState
} from 'react'

import {
    useParams
} from 'react-router-dom'

import Spinner from 'react-bootstrap/Spinner'

import webutils from '@common/webutils.mjs'
import * as _axios from 'axios'

const axios = webutils.axiosToBackend

export default function EmailMe() {

    let { spreadsheetId } = useParams()

    const [spreadsheet, setSpreadsheet] = useState(null)

    const [email, setEmail] = useState("")

    const [hasInit, setHasInit] = useState(false)

    const [isSending, setIsSending] = useState(false)

    const [tableHtml, setTableHtml] = useState("")


    useEffect(() => {
        if(localStorage.getItem("storedEmail")){
            setEmail(localStorage.getItem("storedEmail"))
        }
    },[])

    const storeEmail = (email) => {
        setEmail(email)
        localStorage.setItem("storedEmail",email)
    }

    const clearStoredEmail = () => {
        setEmail("")
        localStorage.removeItem("storedEmail")
    }

    const handleEmailChange = (evt) => {
        setEmail(evt.target.value)
        if(evt.target.value===""){
            clearStoredEmail()
        }
    }

    const handleEmailMe = async (evt) => {
        storeEmail(email)
        if(isSending) {
            console.log("Ignoring excessive button click")
            return
        }
        if(spreadsheet!==null){
            setTableHtml("")
            setIsSending(true)
            try{
                const response = await webutils.axiosToBackend.post("/api/enduserdata/email-me",{
                    spreadsheetId: spreadsheet.spreadsheetId,
                    email:email
                })

                setTableHtml(response.data.data)
            
            }catch(e){
                if(_axios.isAxiosError(e)){
                    if(e.response){
                        if(e.response.data){
                            if(e.response.data.data){
                                if(e.response.data.data.errorObject){
                                    if(e.response.data.data.errorObject.stack){
                                        console.log(e.response.data.data.errorObject.stack)
                                    }
                                }
                            }
                        }
                    }
                }
                console.error(e)
            }
            setIsSending(false)
        }
    
    }

    useEffect(() => {

        if(!hasInit){

            setHasInit(true)
        }

        (async () => {

            try {
                setSpreadsheet(
                    (await axios.post("/api/enduserdata/get-spreadsheet-info", {
                        spreadsheetId: spreadsheetId
                    })).data.data
                )
            } catch (e) {

            }

        })()
    }, [spreadsheet&&spreadsheet.spreadsheetId])

    return <div className="container-fluid">
        <div className="row">
            <div className="col-12" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>

                <h1>
                    Email Me My Data!
                </h1>

                {
                    spreadsheet ? (
                        <div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Spreadsheet</th>
                                        <th>Owner</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{spreadsheet.name}</td>
                                        <td>{spreadsheet.ownerName}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="form">
                                <div className="form-row">
                                    <div className="form-group" style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <label>Your Email Address
                                            <input type="email" className="form-control"
                                            value={email}
                                            onChange={handleEmailChange}
                                            onInput={handleEmailChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-row" style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    <button className='btn btn-primary form-control' onClick={handleEmailMe}>Email Me!</button>
                                    {
                                        isSending && <div style={{
                                            width:"2em",
                                            height:"2em"
                                        }}><Spinner /></div>
                                    }
                                </div>
                                
                                <div>&nbsp;</div>

                                <div dangerouslySetInnerHTML={{__html:tableHtml}}></div>
                                
                            </div>
                        </div>
                    ) : (
                        <span className='text-secondary text-center'>
                            <h4>Loading...</h4>
                            <Spinner />
                        </span>
                    )
                }
            </div>
        </div>
    </div>
}