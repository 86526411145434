import "bootstrap/dist/css/bootstrap.min.css"
import {useEffect, useState} from 'react'
import InputHandler from '../Utils/InputHandler.js'
import {Link} from 'react-router-dom'
import axios from 'axios'
import useToastBox from '../Utils/ToastBox.js'
import '../Styles/common.css'
import Navbar from './Navbar.js'
import jsutils from '../Common/jsutils.mjs'
import { Nav } from "react-bootstrap"
import webutils from '../Common/webutils.mjs'

function CreateAccountPage(){
     
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [ToastBoxComponent, showToastBox, closeToastBox] = useToastBox()
    const [warningMessage, setWarningMessage] = useState("")

    const warnUser = (warning) => {setWarningMessage(warning)}
    
    const handleSubmit = async (e) => {

        if(jsutils.stringIsEmpty(email)){
            warnUser("Please enter an email.")
            return
        }

        
        if(jsutils.stringIsEmpty(name)){
            warnUser("Please enter your name.")
            return
        }

        if(jsutils.stringIsEmpty(password)){
            warnUser("Please enter a password.")
            return
        }

        if(jsutils.stringIsEmpty(confirmPassword)){
            warnUser("Please confirm your password.")
            return
        }

        if(password!==confirmPassword){
            warnUser("Password and confirm password don't match.");
            return
        }


        try{
            await webutils.axiosToBackend.post("api/account/create",{email:email,password:password,name:name})
            warnUser("")
            showToastBox('Account Created Successfully',(
                    <div className='text-center'>
                        Account Created Successfully. <br/>
                        <Link to='/login' className='text-primary link'>
                        Log In
                        </Link>
                    </div>
                ))
        }catch(e){
            showToastBox("Account Creation Error",`Could not create account: ${e.response.data.data}`)
        }
    }
    
    return (
        <div className='container-fluid'>
            <Navbar />
            <div className='row'>
                <div className='col-sm-3'></div>  
                <div className='col-sm-6'>
                    <h1>Smartforms For Microsoft</h1>
                    <div className='mb-3' style={{width:"100%"}}>
                        <label style={{width:"100%"}}>Email
                            <input style={{width:"100%"}} type='text' className='form-control' onChange={InputHandler(setEmail)}/>
                        </label>
                    </div>
                    <div className='mb-3' style={{width:"100%"}}>
                        <label style={{width:"100%"}}>Name
                            <input style={{width:"100%"}} type='text' className='form-control' onChange={InputHandler(setName)}/>
                        </label>
                    </div>
                    <div className='mb-3' style={{width:"100%"}}>
                        <label style={{width:"100%"}}>Password
                            <input style={{width:"100%"}} type='password' className='form-control' onChange={InputHandler(setPassword)}/>
                        </label>
                    </div>
                    <div className='mb-3' style={{width:"100%"}}>
                        <label style={{width:"100%"}}>Confirm Password
                            <input style={{width:"100%"}} type='password' className='form-control' onChange={InputHandler(setConfirmPassword)}/>
                        </label>
                    </div>
                    
                    <button type='button' className='btn btn-primary mb-3' onClick={handleSubmit}>Submit</button>
                    <span className='text-danger'>{warningMessage}</span>
                </div>
                <div className='col-sm-3'></div>  
            </div>
            {ToastBoxComponent}
        </div>
    )
}

export default CreateAccountPage