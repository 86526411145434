import { useEffect, useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";

import webutils from "@common/webutils.mjs";
import { useGlobalState } from "@utils/GlobalState.jsx";

// --- courtesy of emojiterra.com
const pageFacingUpEmoji = "📄";
const fileFolderEmoji = "📁";
const rightArrowFacingUp = "⤴️";
// ---

function Entry(props) {
  return (
    <ListGroup.Item
      style={{
        cursor: "pointer",
        userSelect: "none",
      }}
      active={props.highightedItemId === props.itemId}
      onClick={() => {
        props.setHighlightedItemId(props.itemId);
        props.setMessage("");
        if (props.isDirectory) {
          props.setShowSelectButton(false);
        } else {
          props.setShowSelectButton(true);
        }
      }}
      onDoubleClick={() => {
        if (props.isDirectory) {
          props.setParentItemId(props.containerItemId);
          props.setItemId(props.itemId);
          props.setShowSelectButton(false);
          props.setHighlightedItemId(null);
        } else {
          props.finalizeSelection(props.itemId);
          props.setIsOpen(false);
        }
      }}
    >
      <span
        style={{
          marginRight: "1em",
        }}
      >
        {props.isDirectory ? fileFolderEmoji : pageFacingUpEmoji}
      </span>
      <span>{props.name}</span>
    </ListGroup.Item>
  );
}

export default function FilePicker(props) {
  const [userToken, setUserToken] = useGlobalState("userToken", null);

  const [itemId, setItemId] = useState(null);

  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;

  const [items, setItems] = useState([]);

  const [message, setMessage] = useState("");

  const [showSelectButton, setShowSelectButton] = useState(false);

  var entryComponents = [];

  const [highlightedItemId, setHighlightedItemId] = useState(null);

  const [parentItemId, setParentItemId] = useState(null);

  const finalizeSelection = async (id) => {
    if (id) {
      try {
        const itemInfo = (
          await webutils.postToProtectedRoute(
            "/api/msgraph/drive-item-info",
            userToken,
            {
              itemId: id,
            }
          )
        ).data.data;

        if (itemInfo.folder) {
          setMessage("Cannot select a folder.");
        } else {
          props.finalizeSelection(id);
        }
      } catch (e) {
        console.log(webutils.summarizeError(e));
      }
    } else {
      props.finalizeSelection(null);
    }
  };

  if (itemId) {
    entryComponents.push(
      <ListGroup.Item
        style={{
          cursor: "pointer",
          userSelect: "none",
        }}
        onDoubleClick={() => {
          setItemId(parentItemId);
        }}
      >
        <span>{rightArrowFacingUp}</span>
      </ListGroup.Item>
    );
  }

  items.forEach((item) => {
    entryComponents.push(
      <Entry
        key={item.itemId}
        itemId={item.itemId}
        name={item.name}
        isDirectory={item.isDirectory}
        highightedItemId={highlightedItemId}
        setHighlightedItemId={setHighlightedItemId}
        finalizeSelection={finalizeSelection}
        setItemId={setItemId}
        setIsOpen={setIsOpen}
        parentItemId={parentItemId}
        setParentItemId={setParentItemId}
        containerItemId={itemId}
        setMessage={setMessage}
        setShowSelectButton={setShowSelectButton}
      />
    );
  });

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          const responseData = (
            await webutils.postToProtectedRoute(
              "/api/msgraph/list-children",
              userToken,
              {
                itemId: itemId,
              }
            )
          ).data.data;
          setItems(responseData)
        } catch (e) {
          console.error(webutils.summarizeError(e))
        }
      })();
    }
  }, [itemId, isOpen, userToken])

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => {
          finalizeSelection(null);
          setIsOpen(false);
        }}
      >
        <Modal.Title>Select A File</Modal.Title>

        <Modal.Body>
          <ListGroup>
            <>{entryComponents}</>
          </ListGroup>
        </Modal.Body>

        <Modal.Footer>
          {showSelectButton && (
            <Button
              variant="primary"
              onClick={() => {
                if (highlightedItemId) {
                  finalizeSelection(highlightedItemId);
                  setIsOpen(false);
                } else {
                  setMessage("Please select a file.");
                }
              }}
            >
              Open
            </Button>
          )}
          <div className="text-danger">{message}</div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
