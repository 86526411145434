import "bootstrap/dist/css/bootstrap.min.css"
import {useState} from 'react'
import InputHandler from '../Utils/InputHandler.js'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import axios from 'axios'
import useToastBox from '../Utils/ToastBox.js'
import {useGlobalState} from '../Utils/GlobalState.jsx'
import {useNavigate} from 'react-router-dom'
import Navbar from './Navbar.js'
import jsutils from '../Common/jsutils.mjs'
import webutils from '../Common/webutils.mjs'

function LoginPage(){
     
    window.webutils = webutils

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [ToastBoxComponent,showToastBox,closeToastBox] = useToastBox()
    const [warningMessage, setWarningMessage] = useState("")

    const [userId, setUserId] = useGlobalState("userId",null)
    const [userToken, setUserToken] = useGlobalState("userToken",null)
    
    const navigate = useNavigate()

    const warnUser = (message) => {
        setWarningMessage(message)
    }

    const handleLogin = async (evt) =>{
        
        if(jsutils.stringIsEmpty(email)){
            warnUser("Please enter an email.")
            return
        }

        if(jsutils.stringIsEmpty(password)){
            warnUser("Please enter a password.")
        }

        try{
            const response = (await webutils.axiosToBackend.post("/api/account/login",{email:email,password:password})).data
            const userId = response.data.userId
            const userToken = response.data.token
            setUserId(userId)
            setUserToken(userToken)
            navigate("/dashboard")
            
        }catch(e){
            if(e.response){
                showToastBox("Login Error",`Could not login due to bad response: ${e.response.data.data}`)
            }
            else{
                showToastBox("Login Error",`Could not login: ${e.message}`)
            }
        }

    }
     
    return (
        <div className='container-fluid'>
            <Navbar />
            <div className='row'>
                <div className='col-sm-3'></div>  
                <div className='col-sm-6'>
                    <h1>Smartforms For Microsoft</h1>
                    <div className='mb-3' style={{width:"100%"}}>
                        <label style={{width:"100%"}}>Email
                            <input style={{width:"100%"}} type='text' className='form-control' onChange={InputHandler(setEmail)}/>
                        </label>
                    </div>
                    <div className='mb-3' style={{width:"100%"}}>
                        <label style={{width:"100%"}}>Password
                            <input style={{width:"100%"}} type='password' className='form-control' onChange={InputHandler(setPassword)}/>
                        </label>
                    </div>
                    <button type='button' className='btn btn-primary mb-3' onClick={handleLogin}>Login</button>
                    <span className='text-danger'>{warningMessage}</span>
                    <h5>
                        No account?&nbsp;
                        <Link className="text-primary" style={{
                            textDecoration:'underline',
                            cursor:'pointer',
                            userSelect:'none'
                        }} to='/create-account'>
                            Create One!
                        </Link>
                    </h5>
                </div>
                <div className='col-sm-3'></div>  
            </div>
            {ToastBoxComponent}
        </div>
    )
}

export default LoginPage