import FilePicker from '@components/FilePicker.js'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import pathutils from '../Common/pathutils.mjs'
import webutils from '../Common/webutils.mjs'
import styles, { mix } from '../Styles/commonStyles'
import { hvCenteringBox } from '../Styles/commonStyles.js'
import { useGlobalState } from '../Utils/GlobalState.jsx'
import useToastBox from '../Utils/ToastBox.js'
import Navbar from './Navbar.js'

function ConnectedSpreadsheetEntry(props) {

    let UI_ORIGIN = process.env.NODE_ENV==="production"?"https://smartforms.app/":"http://localhost:3000"

    return <tr>
        <td>{ (!props.webUrl)?
            <>
                <span className="text-secondary">
                    {webutils.normalize_url(pathutils.dirname(props.filepath) + "/")}
                </span>
                <span>
                    {pathutils.basename(props.filepath)}
                </span>
            </>:<>
                <a href={props.webUrl} target="_blank">
                    <span className="text-secondary">
                        {webutils.normalize_url(pathutils.dirname(props.filepath) + "/")}
                    </span>
                    <span>
                        {pathutils.basename(props.filepath)}
                    </span>
                </a>
            </>
            }
        </td>
        <td>
            <a href={"/#/emailme/" + props.id}>
                Go to EmailMe Link
            </a>
        </td>
        <td>
            <a href="#" onClick={async ()=>{
                try{
                    await navigator.clipboard.writeText(
                        webutils.normalize_url(UI_ORIGIN+"/#/emailme/" + props.id)
                    )
                } catch(e) {
                    props.messageUser("Copy to Clipboard Error","Could not copy to clipboard. Go ahead and manually copy: "+ webutils.normalize_url(UI_ORIGIN+"/#/emailme/" + props.id),"danger")
                }
                
            }}>
                Copy EmailMe Link
            </a>
        </td>
        <td>
            <a className="text-danger" style={{cursor:"pointer",userSelect:"none"}}
            
                onClick={()=>{
                    props.handleDisconnectSpreadsheet(props.id)
                }}
            >
                disconnect
            </a>
        </td>
    </tr>
}

function Dashboard() {

    const navigate = useNavigate()

    const [ToastBoxComponent, showToastBox, closeToastBox] = useToastBox()

    const [connectedAccount, setConnectedAccount] = useState(null)

    const [userId, setUserId] = useGlobalState("userId", null)
    const [userToken, setUserToken] = useGlobalState("userToken", null)
    const [userFullName, setUserFullName] = useState("")

    const [connectedSpreadsheets, setConnectedSpreadsheets] = useState([])

    const [filePickerOpen, setFilePickerOpen] = useState(false)
    const [filePickerItem, setFilePickerItem] = useState(null)
    const [filePickerSelectedItemId, setFilePickerSelectedItemId] = useState(null)

    const openFilePicker = () => {
        setFilePickerOpen(true)
    }

    const messageUser = (title, message, bg="light") => {
        showToastBox(title, message, bg)
    }

    const handleConnectAccount = async (evt) => {
        try {
            const microsoftLoginUrl = (await webutils.postToProtectedRoute("/api/msgraph/auth/get-login-url", userToken, {})).data.data
            window.location = microsoftLoginUrl
        } catch (e) {
     
        }
    }

    const handleReconnectAccount = async (evt) => {
        try {
            const microsoftLoginUrl = (await webutils.postToProtectedRoute("/api/msgraph/auth/get-login-url", userToken, {
                useLoginHint: true
            })).data.data
            window.location = microsoftLoginUrl
        } catch (e) {

        }
    }

    const handleConfirmedDisconnectAccount = async (evt) => {
        try {
            const microsoftLogoutUrl = (await webutils.postToProtectedRoute("/api/msgraph/auth/get-logout-url", userToken, {
                useLoginHint: true
            })).data.data
            window.open(microsoftLogoutUrl, "_blank")
        } catch (e) {

        }
    }

    const handleDisconnectAccount = (evt) => {

        messageUser("Are you sure?",<>
            Disconnecting your account will remove all of your connected spreadsheets.
            Use reconnect if you microsoft login is not working or has expeired.<br/>
            <a href="#" onClick={()=>{
                handleConfirmedDisconnectAccount()
            }}>Disconnect Anyway</a>
        </>,"warning")
        
    }

    const attemptConnectSpreadsheet = async (itemId) => {

        try {

            const itemData = (await webutils.postToProtectedRoute("/api/msgraph/drive-item-info", userToken, {
                itemId: itemId
            })).data.data

            const name = itemData.name

            if (!(name.endsWith(".xlsx") || name.endsWith(".xls"))) {
                messageUser("Spreadsheet Connection Error", "File is not an .xls file or a .xlsx file.")
            } else {

                setConnectedSpreadsheets((await webutils.postToProtectedRoute("/api/userdata/connect-spreadsheet", userToken,{
                    itemId:itemId
                })).data.data)

            }

        } catch (e) {

        }
    }

    const handleDisconnectSpreadsheet = async (spreadsheetId) => {
        try{

            setConnectedSpreadsheets((await webutils.postToProtectedRoute(`/api/userdata/disconnect-spreadsheet`, userToken,{
                spreadsheetId:spreadsheetId
            })).data.data)
        }catch(e){
            if(axios.isAxiosError(e)){
                console.log(e.response.data)
                console.log(e.response.data.data)
            }
        }
    }

    var connectedSpreadsheetEntries = []

    connectedSpreadsheets.forEach(item => {
        connectedSpreadsheetEntries.push(
            <ConnectedSpreadsheetEntry webUrl={item.webUrl} key={item.itemId} id={item._id} filepath={item.filepath} spreadsheetId={item._id} handleDisconnectSpreadsheet={handleDisconnectSpreadsheet}
            messageUser = {messageUser} />
        )
    })

    useEffect(() => {
        (async () => {

            if (!userId || !userToken) {
                messageUser("Login Token Missing", 
                    <>
                    Your login token is missing and the dashboard will not work properly. <a href="/login">Return to login page.</a>
                    </>,
                    "danger"
                )
                
            }

            try {
                const response = (await webutils.postToProtectedRoute("/api/userdata/me", userToken, {})).data

                // for debugging purposes, set window.userId and window.userToken
                window.userId = userId;
                window.userToken = userToken;
                setUserFullName(response.data.name)

            } catch (e) {
                messageUser("Login Token Invalid", <>
                Your login token is invalid and the dashboard will not work properly. <a href="/login">Return to login page.</a>
                </>,
                "danger")
            }

            try {
                setConnectedAccount((await webutils.postToProtectedRoute("/api/userdata/connected-account", userToken, {})).data.data)


                setConnectedSpreadsheets((await webutils.postToProtectedRoute("/api/userdata/connected-spreadsheets", userToken, {})).data.data)

            } catch (e) {

            }

        })()
    }, [connectedAccount?connectedAccount.id:null, userId, userToken, JSON.stringify(
        Array.from(connectedSpreadsheets.map(item=>item._id))
        )])

    return (
        <div className='container-fluid'>
            <Navbar userFullName={userFullName}/>
            <div className='row'>
                <div className='col-sm-3'>
                    <div className="panel panel-default">
                        {
                            (connectedAccount) ? (
                                <div className="panel panel-default">
                                    <div style={mix(hvCenteringBox("column"), {width: "100%"})}>
                                        <h1>Connected Account</h1>
                                    </div>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><b>Username</b></td>
                                            <td>{connectedAccount.username}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Name</b></td>
                                            <td>{connectedAccount.name}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Id</b></td>
                                            <td>{connectedAccount.homeAccountId}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Health</b></td>
                                            <td>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    {connectedAccount.health}
                                                    <div>&nbsp;</div>
                                                    <div className="text-danger" style={styles.link}
                                                         onClick={handleDisconnectAccount}>disconnect
                                                    </div>
                                                    <div>&nbsp;</div>
                                                    <div className="text-primary" style={styles.link}
                                                         onClick={handleReconnectAccount}>reconnect
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="panel panel-default" style={hvCenteringBox('column')}>
                                    <button className="btn btn-primary" onClick={handleConnectAccount}>Connect Microsoft
                                        Account
                                    </button>
                                </div>
                            )
                        }


                    </div>
                </div>
                <div className='col-sm-6' style={hvCenteringBox('column')}>


                    <hr/>


                    <hr/>

                    <div className="panel panel-default">
                        <div className="panel panel-heading" style={hvCenteringBox('column')}>
                            <h3>Connected Spreadsheets</h3>
                        </div>
                        <div className="panel panel-body" style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>

                            {
                                (connectedSpreadsheets) && (
                                    <table className="table">
                                        <tbody>
                                        {connectedSpreadsheetEntries}
                                        </tbody>
                                    </table>
                                )
                            }


                            <Button variant="light" style={{
                                fontSize: "3em"
                            }}
                                    onClick={openFilePicker}
                            >&nbsp;+&nbsp;</Button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'></div>
            </div>
            {ToastBoxComponent}
            <FilePicker
                messageUser={messageUser}
                isOpen={filePickerOpen}
                setIsOpen={setFilePickerOpen}
                selectedItemId={filePickerSelectedItemId}
                setSelectedItemId={setFilePickerSelectedItemId}
                finalizeSelection={(id) => {
                    if (id) {

                        attemptConnectSpreadsheet(id)
                    } else {

                    }
                }}
            />
        </div>

    )

}

export default Dashboard