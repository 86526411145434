import {useGlobalState} from "../Utils/GlobalState.jsx"
import {Link} from 'react-router-dom'

function Navbar(props){

    const [userId,setUserId] = useGlobalState("userId",null)

    const isLoggedIn = !!userId

    // --- Adapted from https://getbootstrap.com/docs/5.2/components/navbar/

    // return  <nav className="navbar bg-light">

    //                 <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                
    //                     {isLoggedIn?(
    //                         <>
    //                         <li className="nav-item">
    //                             <Link className="nav-link" aria-current="page" to='/dashboard'>Dashboard</Link>
    //                         </li>
    //                         <li className="nav-item">
    //                         <Link className="nav-link" aria-current="page" to='/logout'>Log Out</Link>
    //                         </li>
    //                         </>
    //                     ):(<>

    //                     <li className="nav-item">
    //                     <Link className="nav-link" aria-current="page" to='/login'>Log In</Link>
    //                     </li>
    //                     <li className="nav-item">
    //                     <Link className="nav-link" aria-current="page" to='/create-account'>Create Account</Link>
    //                     </li>
                
    //                     </>)}
                
    //                 </ul>

        
    //         </nav>

    // ---


    return <div className="row bg-light" style={{width:"100%",height:"6em"}} >
        <div style={{width:"100%", height:"6em",position:"absolute",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} >
            <div><Link to="/" style={{textDecoration:"none",justifySelf:"center"}}><h2>Smart📝s For Microsoft</h2></Link>
            {(isLoggedIn&&props.userFullName)&&`Welcome: ${props.userFullName}`}
            </div>
        </div>
        <div style={{width:"100%",  height:"6em",position:"absolute",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}} >
            {(isLoggedIn) ? (
                <>  
                    <div className="btn-group" >         
                        <button className="btn btn-light" >
                            <Link   to='/dashboard' style={{color:"black",textDecoration:"none"}}><h3>Dashboard</h3></Link>
                        </button>
                        <button className="btn btn-light" >
                            <Link   to='/logout' style={{color:"black",textDecoration:"none"}}><h3>Log Out</h3></Link>
                        </button>
                    </div>

                </>
            ) : (
                <>
                    <div className="btn-group">
                        <button className="btn btn-light" >
                            <Link  to='/login' style={{color:"black",textDecoration:"none"}}><h3>Log In</h3></Link>
                        </button>
                        <button className="btn btn-light" >
                            <Link  to='/create-account' style={{color:"black",textDecoration:"none"}}><h3>Create Account</h3></Link>
                        </button>
                    </div>
                </>
            )}
        </div>
    </div>

}

export default Navbar