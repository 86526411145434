import url from 'url'

function stripString(str) {
    return (str??"").replace(/^ +/g,"").replace(/ +$/g,"").replace(/^\t+/g,"").replace(/\t+$/g,"").replace(/^\n+/g,"").replace(/\n+$/g,"")
}

function stringIsEmpty(str){
    return !stripString(str)
}

function tryConvertPrimitive(obj){
    if(typeof obj === "undefined") {
        return [null, true] // Coerce undefined to null
    }
    if(typeof obj === "string"){
        return [obj, true]
    }
    if(typeof obj === "number"){
        return [obj, true]
    }
    if(typeof obj === "boolean"){
        return [obj, true]
    }
    if(typeof obj === "null"){
        return [obj, true]
    }
    if(typeof obj === "bigint"){
        throw new Error("BigInt is not supported")
    }
    if(typeof obj === "symbol"){
        throw new Error("Symbol is not supported")
    }
    if(typeof obj === "function"){
        throw new Error("Function is not supported")
    }
    return [obj, false]
}

function toPlainObject(obj, references=[]){
    const [conversion, canConvert] = tryConvertPrimitive(obj)
    if(canConvert)
        return conversion
    let plainObject = {}
        Object.getOwnPropertyNames(obj).forEach((key) => {
            const innerObj = obj[key]
            if(!references.includes(innerObj)){
                const [innerConversion, canConvertInner] = tryConvertPrimitive(innerObj)
                if(canConvertInner){
                    plainObject[key] = innerConversion
                }else{
                    if(!references.includes(innerObj)){
                        references.push(innerObj)
                        plainObject[key] = toPlainObject(innerObj, references)
                    } // Do not attempt to "report" circular references with something like string "(circular)" just omit them.
                }
            } 
        })
    return plainObject
}


function smartStringify(obj,_=null,spaces=2) {


    return JSON.stringify(toPlainObject(obj), null,spaces)

}

function tryStringify(obj, prettify=false, prettifySpaces=2){
    let stringified = JSON.stringify({})
    try{
        if(prettify)
            stringified = smartStringify(obj, null, prettifySpaces)
        else
            stringified = smartStringify(obj)
    }catch(e){}
    return stringified
}

function importMetaUrlTo__dirname(u){
    const __filename = url.fileURLToPath(u)
    let components =__filename.replace(/\\/g,"/").replace(/\/$/g,"").split("/").slice()
    components = components.slice(0,components.length-1)
    const __dirname = components.join("/")
    return __dirname
}


var jsutils =  {
    stripString:stripString,
    stringIsEmpty:stringIsEmpty,
    tryStringify:tryStringify,
    smartStringify:smartStringify,
    importMetaUrlTo__dirname:importMetaUrlTo__dirname
}

export default jsutils