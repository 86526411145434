import "bootstrap/dist/css/bootstrap.min.css"
import {useGlobalState} from '../Utils/GlobalState.jsx'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import axios from 'axios'


function DefaultPage(){
    
    const [userId, setUserId] = useGlobalState("userId",null)
    
    const navigate = useNavigate()
    
    useEffect(function(){
        
        if(userId===null){
            navigate("/login")
        }else{
            navigate("/dashboard")
        }
    })
    
    return (
        <div style={{
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
        }}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default DefaultPage