import webutils from '../Common/webutils.mjs';
import {PersistentStateManager} from '@utils/GlobalState'

function debug_msFetch(method, url, data = {}) {
    const userToken = window.userToken;
    (async ()=>{
        const responseData = (await webutils.postToProtectedRoute("/api/msgraph/debug-msfetch",userToken,{
            url:url,
            method:method,
            data:data
        })).data
        console.log(responseData)
    })()
}

function apiPost(url,data={}){ // If the route is not protected, should not make a difference. Will just have an extra userToken field in req.body
    const userToken = window.userToken;
    (async ()=>{
        const responseData = (await webutils.postToProtectedRoute(webutils.normalize_url(`/api/${url}`),userToken,data)).data
        console.log(responseData)
    })()
}

class InBrowserDevTools {

    static init() {
        window.msFetch = debug_msFetch
        window.msGet= (url)=>debug_msFetch("GET",url)
        window.msPost=  (url,data)=>debug_msFetch("POST",url,data)
        window.apiPost = apiPost
        window.forceRefreshMSGraphToken = ()=>{
            apiPost("msgraph/debug-force-token-refresh",{})
        }
        window.PersistentStateManager = PersistentStateManager

    }

}

export default InBrowserDevTools
