import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, HashRouter} from 'react-router-dom'
import LoginPage from './Components/LoginPage.js'
import DefaultPage from './Components/DefaultPage.js'
import CreateAccountPage from './Components/CreateAccountPage.js'
import Dashboard from './Components/Dashboard.js'
import LogoutPage from './Components/LogoutPage.js'
import EmailMePage from './Components/EmailMePage.js'
import {GlobalStateRoot} from './Utils/GlobalState'

// Modifies the window object, so only need to import and init here
import InBrowserDevTools from './Utils/InBrowserDevTools';
InBrowserDevTools.init()

function App() {

  return (
      <GlobalStateRoot>
        <HashRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/create-account" element={<CreateAccountPage />}/>
            <Route path='/dashboard' element={<Dashboard />}/>
            <Route path='/logout' element={<LogoutPage />}/>
            <Route exact path="/" element={<DefaultPage />} />
            <Route exact path="/emailme/:spreadsheetId" element={<EmailMePage/>} />
          </Routes>
        </HashRouter>
      </GlobalStateRoot>
  )
}

export default App;
