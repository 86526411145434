import "bootstrap/dist/css/bootstrap.min.css"
import {useGlobalState, useResetGlobalState} from '../Utils/GlobalState.jsx'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import axios from 'axios'
import webutils from '../Common/webutils.mjs'
import Debug from "../Common/debugging.mjs"


function LogoutPage(){
    
    const [userId, setUserId] = useGlobalState("userId",null)
    const [userToken, setUserToken] = useGlobalState("userToken",null)
    
    const navigate = useNavigate()
    
    useEffect(function(){
        (async () => {
            setUserId(null)
            setUserToken(null)

//            try {
//                await webutils.axiosToBackend.post("/api/account/express-session-destroy")
//            }catch(e){
//
//            }

            navigate("/login")
        })()
    })
    
    return (
        <div style={{
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
        }}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Logging you out...</span>
            </div>
        </div>
    )
}

export default LogoutPage