import Toast from 'react-bootstrap/Toast'
import {useState} from 'react'

function ToastBoxComponent(title,content,showState,setShowState,closeCallback, bg){

    return (
        <Toast bg={bg} animation={true} show={showState} onClose={()=>{
            setShowState(false)
            closeCallback()
        }}>
            <Toast.Header>
                {title}
            </Toast.Header>
            <Toast.Body className={(bg.toLowerCase()==="dark")&&"text-white"}>
                {content}
            </Toast.Body>
        </Toast>
    )
}

function useToastBox(closeCallback = ()=>{}){
    const [showState, setShowState] = useState(false)
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [bg, setBg] = useState("light")
    const showToastBox = (title,content,bg="light")=>{
        setTitle(title)
        setContent(content)
        setShowState(true)
        setBg(bg)
    }
    const closeToastBox = () => {
        setShowState(false)
    }
    const component = ToastBoxComponent(title,content,showState,setShowState,closeCallback,bg)
    return [component, showToastBox,closeToastBox]
}

export default useToastBox