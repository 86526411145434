import jsutils from './jsutils.mjs'


// --- Borrowed from my nnj2 project (D:/buisnametbd/nnj2)

// Check if using node or react
const caller = (typeof window === "undefined") ? "node" : "react"

// List terminal color escape codes

const RESET = "\u001b[0m"

var RED = "\u001b[31m"
var GREEN = "\u001b[32m"
var YELLOW = "\u001b[33m"
var BLACK = "\u001b[30m"
var BLUE = "\u001b[34m"
var MAGENTA = "\u001b[35m"
var CYAN = "\u001b[36m"

if( caller === "react") {
    RED="red"
    GREEN="green"
    YELLOW="yellow"
    BLACK="black"
    BLUE="blue"
    MAGENTA="magenta"
    CYAN="cyan"
}

class Debug {

    constructor(tag) {
        this.tag = tag
    }

    output(color, message) {
        Debug.output(color, this.tag + ": "+message)
    }

    outputObject(color, obj) {
        var stringified = "(stringify failed)"

        // check if obj is object or string
        if (typeof obj === "object") {
            try {
                stringified = jsutils.tryStringify(obj, true, 2)
            } catch (e) {
    
            }
        }
        
        if (typeof obj === "string") {
            stringified = obj;
        }

        stringified.split("\n").forEach((line) => {
            this.output(color, line)
        })
    }

    
    log(obj){
        this.outputObject(null, obj)
    }

    error(obj){
        this.outputObject(RED, obj)
    }

    info(obj){
        this.outputObject(BLUE, obj)
    }

    warn(obj){
        this.outputObject(YELLOW, obj)
    }

    success(obj){
        this.outputObject(GREEN, obj)
    }
    
    static output(color, message) {

        if (color){
            if (caller === "node") {
        
                console.log(color + message + RESET);
            
            }else{
    
                console.log("%c"+message,"color:"+color);
    
            }
        }else{
            console.log(message)
        }

    }

    static outputObject(color, obj) {

        var stringified = "(stringify failed)"

        // check if obj is object or string
        if (typeof obj === "object") {
            try {
                stringified = jsutils.tryStringify(obj, true, 2)
            } catch (e) {
    
            }
        }
        
        if (typeof obj === "string") {
            stringified = obj;
        }

        stringified.split("\n").forEach((line) => {
            Debug.output(color, line)
        })
    }

    static log(obj){
        Debug.outputObject(null, obj)
    }

    static error(obj){
        Debug.outputObject(RED, obj)
    }

    static info(obj){
        Debug.outputObject(BLUE, obj)
    }

    static warn(obj){
        Debug.outputObject(YELLOW, obj)
    }

    static success(obj){
        Debug.outputObject(GREEN, obj)
    }

}

export default Debug;

// --- 
