import {useState} from 'react'

const storage = window.sessionStorage

export class PersistentStateManager {
    static init(){
        if(!storage.getItem("persistentState")){
            storage.setItem("persistentState", JSON.stringify({}));
        }
    }
    static read(key, defaultValue=null){
        PersistentStateManager.init();
        let state = JSON.parse(storage.getItem("persistentState"));
        return state[key]??defaultValue
    }
    static write(key, value=null){
        PersistentStateManager.init()
        let state = JSON.parse(storage.getItem("persistentState"));
        state[key] = value
        storage.setItem("persistentState", JSON.stringify(state));
    }
    static readAll(){
        return JSON.parse(storage.getItem("persistentState"))
    }
}


function useGlobalState(name, defaultValue){
    PersistentStateManager.init()
    return [PersistentStateManager.read(name, defaultValue), (value=null)=>{
        PersistentStateManager.write(name, value)
        window.GlobalStateRoot.forceRerender()
    }]
}

export { useGlobalState }

export function GlobalStateRoot(props) {
    
    let [renderCounter, setRenderCounter] = useState(0)

    let forceRerender = ()=>{
        setRenderCounter(renderCounter + 1)
    }

    window.GlobalStateRoot = {
        forceRerender: forceRerender
    }
    
    return <>
        {props.children}
    </>
}

